import styled from "styled-components";
import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import WindowFrame from "./WindowFrame/WindowFrame";
import {
  focusAbout,
  blurAbout,
  minimizeAbout,
  exitAbout,
} from "../../store/actions/actions";
import aboutImage from "../../assets/taskbar-icons/about.png";
import Button from "../Button";
import { useNavigate } from "react-router-dom";
import Slackpfp from "../../assets/about-images/slackpfp.png";
import Slackpfp2 from "../../assets/about-images/slackpfp2.png";
import Slackreaction from "../../assets/about-images/parrot.gif";

const StyledContainer = styled.div`
  padding-top: 0px;
  padding-bottom: 0px;
  .images img {
    width: 980px;
    height: 90px;
  }

  .container {
    display: flex;
    overflow: visible;
    max-height: 77vh;
    overflow-y: auto;
    justify-content: space-between;
    .text {
      padding-left: 15px;
      padding-right: 15px;
      text-align: left;
      flex: 2;
      line-height: 18px;
    }
  }
  .button-container {
    padding-top: 15px;
    text-align: right;
  }

  .column {
    flex-basis: 50%;
    text-align: center;
  }

  .pricing-table {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid black;
    border-radius: 15px;
    padding: 5px;
    margin-bottom: 16px;
    box-shadow: 8px 8px 0 black;
  }

  .plan {
    padding: 20px;
    text-align: center;
  }

  .plan-name {
    font-size: 24px;
    font-weight: bold;
    padding-bottom: 5px;
  }

  .plan-price {
    font-size: 36px;
    font-weight: 600;
  }

  .plan-features {
    list-style: none;
    padding: 0;
    margin: 20px 0;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }

  .plan-features li {
    display: flex;
    align-items: center;
  }

  .tick-icon {
    margin-right: 5px;
    color: #0865ff;
  }

  .plan-button {
    display: block;
    width: 100%;
    background-color: #0865ff;
    padding: 15px 0;
    border-radius: 8px;
    transition: background-color 0.3s ease;
    margin-top: 20px;
  }

  .btn-text {
    color: #fff;
    font-size: 14px;
    font-weight: bold;
  }

  a {
    text-decoration: none !important;
    -webkit-text-decoration: none !important;
  }

  .text-center {
    padding-left: 15px;
    padding-right: 15px;
    text-align: center;
    flex: 2;
    line-height: 18px;
  }

  .plan-button:hover {
    background-color: #006dff;
  }

  .space-wtf {
    height: 25px;
  }
`;

function About({
  about,
  onAboutFocus,
  onAboutBlur,
  onAboutMinimize,
  onAboutExit, 
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    window.addEventListener("click", aboutBlur);
    return () => window.removeEventListener("click", aboutBlur);
    // eslint-disable-next-line
  }, []);

  function aboutBlur(event) {
    if (
      !document.querySelector("#About").contains(event.target) &&
      !document.querySelector("#about-button").contains(event.target)
    ) {
      onAboutBlur();
    } else {
      onAboutFocus();
    }
  }

  const handleClickExit = () => {
    dispatch(exitAbout());
    navigate("/");
  };

  const windowX = 20;
  const windowY = 20;

  const displayContent = about.show ? (
    <WindowFrame
      id="About"
      x={windowX}
      y={windowY}
      width="800"
      height="385"
      img={aboutImage}
      title="About Us"
      blurred={about.blurred}
      showMenu={false}
      onMinimize={onAboutMinimize}
      onExit={handleClickExit}
      isMinimized={about.minimized}
    >
      <StyledContainer>
        <div className="container">
            <div className="text">
            <h1>We’re building nothing short of a benchmark <br/>
            e-commerce agency.</h1>
            <p>SocialMate is a boutique social media marketing agency operating from the land down under. We're architecting an experience of excellence, and setting the standard for what it means to be your growth partner.</p>
            <p>All while speaking with a funny accent.</p>
            <p>We exist to increase your brand's revenue through paid social media ads.</p>
            <p>Our mission is to reverse all the wrongdoings that you may have from previous agency experiences, and show you what it really takes to scale your e-commerce brand.</p>
            <h2>We'd love to have a chat, click 'Apply Now' to see if your brand qualifies.</h2>

  <div class="slack-container">
  <div class="message-container">
  <img src={Slackpfp} class="user-avatar" alt="SocialMate Slack Message" />
  <div class="message-content">
  <div class="username-content">
      <div class="username">CJ</div>
      <div class="timestamp">9:22 AM</div>
  </div>
      <div class="message-text">Wow nearly $10k before noon!</div>
      <span class="reaction-badge">
    <span class="emoji">🚀</span>
    <span class="count">2</span>
  </span>
  <span class="reaction-badge">
  <img src={Slackreaction} width='auto' height='20px' class="emoji-gif" alt="SocialMate Slack Reaction" />
    <span class="count">2</span>
  </span>
  <div class="replies-info">
    <img src={Slackpfp} class="user-avatar-reply" alt="SocialMate Slack Message" />
    <img src={Slackpfp2} class="user-avatar-reply" alt="SocialMate Slack Message" />
      <span class="replies-link">2 replies</span>
      <span class="last-reply-timestamp">Last reply 9 minutes ago</span>
    </div>
    </div>
  </div>
  <div class="message-container">
  <img src={Slackpfp} class="user-avatar" alt="SocialMate Slack Message" />
  <div class="message-content">
  <div class="username-content">
      <div class="username">CJ</div>
      <div class="timestamp">9:23 AM</div>
  </div>
      <div class="message-text">Jordan I think we may need to start trimming ad spend in AU as we are low on inventory and won’t be restocked with additional 10,000 units until early next week</div>
      <span class="reaction-badge">
    <span class="emoji">😓</span>
    <span class="count">2</span>
  </span>
  <span class="reaction-badge">
    <span class="emoji">✅</span>
    <span class="count">1</span>
  </span>
    </div>
  </div>
  </div>
  <div class='space-wtf'></div>
  <a href="/apply" class="button-primary">Apply now</a>
            <div class='space-wtf'></div>
          </div>
        </div>
        {/* <div className="button-container">
            <Button pad="35" clicked={handleClickExit}>OK</Button>
          </div> */}
      </StyledContainer>
    </WindowFrame>
  ) : null;

  return displayContent;
}

const mapStateToProps = (state) => {
  return {
    about: state.about,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAboutBlur: () => dispatch(blurAbout()),
    onAboutFocus: () => dispatch(focusAbout()),
    onAboutMinimize: () => dispatch(minimizeAbout()),
    onAboutExit: () => dispatch(exitAbout()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(About);
