import React, { useState, useEffect, useRef } from "react";

import { StyledFrame, TitleBar, ButtonGroup, StyledMenu } from "./FrameStyled";
import minimize from "../../../assets/titlebar-icons/minimize.png";
import maximizeDisabled from "../../../assets/titlebar-icons/maximize-disabled.png";
import close from "../../../assets/titlebar-icons/close.png";
import { useMediaQuery } from "../../../hooks/useMediaQuery";

function random(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

function clamp(value, min, max) {
  return Math.min(Math.max(min, value), max);
}

function Frame({
  children,
  id,
  img,
  title,
  blurred,
  showMenu,
  width,
  onMinimize,
  onExit,
  isMinimized,
}) {
  const frameRef = useRef(null);

  const [coordinates, setCoordinates] = useState(() => {
    const randomX = random(100, 200);
    const randomY = random(0, 200);
    const maxX = frameRef.current
      ? window.innerWidth - frameRef.current.offsetWidth
      : 200;

    const maxY = frameRef.current
      ? window.innerHeight - frameRef.current.offsetHeight - 32
      : 200;

    return { x: clamp(randomX, 0, maxX), y: clamp(randomY, 0, maxY) };
  });

  useEffect(() => {
    const maxX = frameRef.current
      ? window.innerWidth - frameRef.current.offsetWidth
      : 200;

    const maxY = frameRef.current
      ? window.innerHeight - frameRef.current.offsetHeight - 32
      : 200;

    setCoordinates((oldPos) => ({
      x: clamp(oldPos.x, 0, maxX),
      y: clamp(oldPos.y, 0, maxY),
    }));
  }, []);

  const [offset, setOffset] = useState({ x: coordinates.x, y: coordinates.y });

  const isLg = useMediaQuery("sm");

  useEffect(() => {
    const frameTitle = document.querySelector("#" + id + " .title");
    frameTitle.addEventListener("mousedown", dragStart);

    return () => frameTitle.removeEventListener("mousedown", dragStart);
    // eslint-disable-next-line
  }, [offset]);

  function dragStart(event) {
    window.onmousemove = (e) =>
      dragging(e, { x: event.clientX, y: event.clientY });
    window.onmouseup = dragEnd;
  }

  function dragging(event, axis) {
    let x = axis.x - event.clientX;
    let y = axis.y - event.clientY;
    const frameHeight = frameRef.current?.offsetHeight;
    const frameWidth = frameRef.current?.offsetWidth;

    const clampedX = clamp(
      coordinates.x - x,
      0,
      window.innerWidth - frameWidth
    );

    const clampedY = clamp(
      coordinates.y - y,
      0,
      window.innerHeight - frameHeight - 32
    );

    setOffset({ x: event.clientX, y: event.clientY });
    setCoordinates({ x: clampedX, y: clampedY });
  }

  function dragEnd() {
    window.onmousemove = null;
    window.onmouseup = null;
  }

  const menu = showMenu ? (
    <StyledMenu>
      <span>
        <u>F</u>ile
      </span>
      <span>
        <u>E</u>dit
      </span>
      <span>
        <u>S</u>earch
      </span>
      <span>
        <u>H</u>elp
      </span>
    </StyledMenu>
  ) : null;

  return (
    <StyledFrame
      left={isLg ? coordinates.x : 5}
      top={isLg ? coordinates.y : 5}
      id={id}
      width={width}
      isMinimized={isMinimized}
      blurred={blurred}
      ref={frameRef}
    >
      <TitleBar blurred={blurred}>
        <img src={img} alt="Window" draggable="false" />

        <span className="title">{title}</span>

        <ButtonGroup>
          <button className="clickable" onClick={() => onMinimize()}>
            <img src={minimize} draggable="false" alt="Minimize" />
          </button>

          <button>
            <img src={maximizeDisabled} draggable="false" alt="Maximize" />
          </button>

          <button className="clickable" onClick={() => onExit()}>
            <img src={close} draggable="false" alt="Close" />
          </button>
        </ButtonGroup>
      </TitleBar>

      {menu}

      {children}
    </StyledFrame>
  );
}

export default Frame;
