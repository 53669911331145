import React, { useCallback, useMemo } from "react";
import styled from "styled-components";
import ditherBackground from "../../styles/ditherBackground";
import { random } from "../../utils";
import { useMediaQuery } from "../../hooks/useMediaQuery";
const StyledIcon = styled.div`
  height: 42px;
  width: 42px;
  background-image: url(${(props) => props.background});
  background-size: 42px auto;
  cursor: pointer;

  &::after {
    content: undefined;
    display: block;
    width: 36px;
    height: 36px;
    mask-image: ${(props) => `url(${props.background})`};
    mask-size: 36px 36px;
    ${() => ditherBackground("rgb(0, 0, 127)")};
  }
`;

const StyledIconWithLabel = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 25px;
  position: absolute;
  top: ${({ top }) => top};
  left: ${({ left }) => left};
  img {
    width: 42px;
    height: 42px;
  }
  span {
    color: rgb(255, 255, 255);
    font-size: 11px;
    text-shadow: black 0px 1px 1px;
    padding: 1px 2px;
    margin-top: 7px;
    border: 1px solid transparent;
  }
  ${(props) =>
    props.clicked &&
    `
      span {
        background-color: rgb(0, 0, 127);
        border: 1px dotted rgba(200, 200, 200);
        cursor: pointer;
      }
      
      ${StyledIcon}::after {
        content: "";
      }
  `}
`;

function Icon({ handleClick, label, img, clicked, top, left }) {
  return (
    <StyledIconWithLabel
      onClick={() => handleClick(label)}
      clicked={clicked}
      top={top}
      left={left}
    >
      <StyledIcon background={img} />
      <span>{label}</span>
    </StyledIconWithLabel>
  );
}

export default Icon;
